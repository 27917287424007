import { defineStore } from "pinia";
import {
  writeContract,
  readContract,
  watchAccount,
  waitForTransactionReceipt,
} from "@wagmi/core";
import Web3 from "web3";

// ============================== 스토어 & 서비스 ================================
import { commonStore, modalStore, userStore, walletApiStore } from "@/main";
import {
  WALLET_SETTING,
  WALLET_CONTRACT,
  WALLET_API,
} from "@/assets/store/api/json/wallet";

export const useWallettApiStore = defineStore("walletApiStore", {
  state: () => ({
    chain_id: WALLET_SETTING.chain_id,
    config: WALLET_SETTING.config,
  }),
  getters: {},
  actions: {
    // ============================== 계정 변경 감지 ================================
    watchAccountEvent() {
      watchAccount(this.config, {
        onChange: async (data) => {
          userStore.setUserAddress(data.address);

          const token_balance = await this.getTokenBalance();
          userStore.setUserTokenBalance(token_balance);
          for (let i = 0; i < commonStore.ROUTE_MINTING.length; i++) {
            const response = await this.checkAdmin(
              commonStore.ROUTE_MINTING[i].route
            );
            if (response == true) return;
          }
        },
      });
    },

    // 천 단위 숫자 콤마 추가
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },

    // ============================== 토큰 잔액 조회 ================================
    async getTokenBalance() {
      if (userStore.user.address == undefined || userStore.user.address == "") {
        return 0;
      }

      const args = [userStore.user.address];

      try {
        const response = await walletApiStore.callReadContract(
          WALLET_API.TYPE.TOKEN,
          WALLET_API.TOKEN.GET_BALANCE,
          args
        );

        const token_balance = Web3.utils.fromWei(response, "ether");
        return this.numberWithCommas(Number(token_balance).toFixed(0));
      } catch (error) {
        console.error(error);
      }
    },

    // ============================== 주소 유효성 검사 ================================
    async checkValidAddress(address) {
      try {
        const checkAddress = address.trim();

        if (!checkAddress) {
          return "";
        }

        const isValidAddress = Web3.utils.isAddress(checkAddress);

        return isValidAddress ? checkAddress : false;
      } catch (error) {
        console.error("Error checking valid address:", error);
        return false;
      }
    },

    // ============================== 트랜잭션 대기 ================================
    async waitTransaction(hash) {
      try {
        const transactionReceipt = await waitForTransactionReceipt(
          this.config,
          {
            chainId: this.chain_id,
            hash: hash,
          }
        );

        return transactionReceipt.status === "success";
      } catch (error) {
        console.error("Error waiting for transaction receipt:", error);
        return false;
      }
    },

    // ============================== ABI 함수 호출 =================================
    async callWriteContract(type, method, args) {
      const contract = WALLET_CONTRACT[type];

      if (!contract) {
        throw new Error(`Invalid contract type: ${type}`);
      }

      if (args === undefined) {
        args = [];
      }

      try {
        const response = await writeContract(this.config, {
          address: contract.ADDRESS,
          abi: contract.ABI,
          functionName: method,
          chainId: this.chain_id,
          args: args,
        });

        const transaction = await this.waitTransaction(response);

        if (transaction) return true;
      } catch (error) {
        if (error.message.includes("OwnableUnauthorizedAccount")) {
          modalStore.showAlertModal(
            "Error",
            "You are not currently an administrator account."
          );
          return false;
        }
        const error_message = modalStore.errorHandle(error);
        if (error_message == "" || error_message == undefined) {
          modalStore.showAlertModal("Error", "An unknown error has occurred.");
        } else {
          modalStore.showAlertModal("Error", error_message);
        }
        return false;
      }
    },

    async callReadContract(type, method, args) {
      const contract = WALLET_CONTRACT[type];

      if (!contract) {
        throw new Error(`Invalid contract type: ${type}`);
      }

      if (args === undefined) {
        args = [];
      }

      try {
        const response = await readContract(this.config, {
          address: contract.ADDRESS,
          abi: contract.ABI,
          functionName: method,
          chainId: this.chain_id,
          args: args,
        });

        return response;
      } catch (error) {
        console.log("Error reading contract : ", error);
        const error_message = modalStore.errorHandle(error);
        if (error_message == "" || error_message == undefined) {
          if (error.message.includes("HTTP request failed.")) {
            return false;
          }
          modalStore.showAlertModal("Error", "An unknown error has occurred.");
        } else {
          modalStore.showAlertModal("Error", error_message);
        }
        return false;
      }
    },

    // ============================== 관리자 계정 확인 =================================
    async checkAdmin(minting_type) {
      try {
        const admin = await this.callReadContract(
          minting_type,
          WALLET_API.MINTING.GET_ADMIN
        );

        if (admin) {
          userStore.setUserRole(userStore.user.address == admin);
          if (userStore.user.address == admin) return true;
        } else {
          userStore.setUserRole(false);
        }

        return admin;
      } catch (error) {
        console.error("Error Check Admin : ", error);
      }
    },
  },
});
